import {nth} from "lodash-es";
import AudioPlayer from "./AudioPlayer";
import PlayButton from "./PlayButton";
import {reactive} from "vue";
import pauseIcon from "./assets/audio_pause.svg";
import playIcon from "./assets/audio_play.svg";
import closeIcon from "./assets/close.svg";
import nextSongIcon from "./assets/next_song.svg";
class SongfinchAudioPlayerPlaylistError extends Error {
    constructor(message, selectedIndex, playlistLength) {
        //Construct a human readable message including arguments from the player
        message = `${message}, selectedIndex=${selectedIndex}, playlistLength=${playlistLength}`;
        super(message); // (1)
        this.name = "SongfinchAudioPlayerPlaylistError"; // (2)
        this.selectedIndex = selectedIndex;
        this.playlistLength = playlistLength;
    }
}

export default {
    install(app, {icons} = {}) {
        app.config.globalProperties.$sfPlayer = reactive({
            currentlyPlayingAudio: {},
            currentRange: null,
            playlist: [],
            currentPlaylistIndex: -1,
            hidePlayerState: true,
            isRendered: false,
            clearNextTimeout: null,
            seekRedraw: false,
            icons: {
                pauseIcon: icons?.pauseIcon ?? pauseIcon,
                playIcon: icons?.playIcon ?? playIcon,
                closeIcon: icons?.closeIcon ?? closeIcon,
                nextSong: icons?.nextSong ?? nextSongIcon,
            },
            get isPlaying() {
                return this.currentlyPlayingAudio?.isPlaying ?? false;
            },
            hidePlayer(hidePlayerState) {
                if (hidePlayerState) this.pause();
                document.body.classList.toggle("playerVisible", !hidePlayerState);
                this.hidePlayerState = hidePlayerState;
            },
            clearNext() {
                clearTimeout(this.clearNextTimeout);
                this.clearNextTimeout = setTimeout(() => {
                    this.playlist = this.playlist.filter(a => {
                        if (a.removeNext) {
                            if (this.currentlyPlayingAudio === a) {
                                this.currentlyPlayingAudio = {};
                                this.currentPlaylistIndex = -1;
                                this.hidePlayer(true);
                            }
                            return false;
                        }
                        return true;
                    });
                });
            },
            setRendered(val) {
                this.isRendered = val;
            },
            removeMinify() {
                document.body.classList.remove("minifyPlayer");
            },
            findMusicDataIndex(data) {
                return this.playlist.findIndex(a => a === data);
            },
            addToPlayList(val) {
                //Make sure we initialize the audio file as not playing
                if (!("isPlaying" in val)) {
                    val.isPlaying = false;
                }
                this.playlist.push(val);
                return this.playlist.length - 1;
            },
            setCurrentPlaylistIndex(idx) {
                //Only swap the song if we are given a new index
                if (idx !== this.currentPlaylistIndex) {
                    //Make sure we indicate that any previously playing song is now stopped
                    this.currentlyPlayingAudio.isPlaying = false;
                    const next = nth(this.playlist, idx);
                    if (!next) {
                        throw new SongfinchAudioPlayerPlaylistError(
                            "Invalid playlist index selected", idx, this.playlist.length
                        );
                    }
                    this.currentPlaylistIndex = idx;
                    this.currentlyPlayingAudio = next;
                }
            },
            toggle() {
                if (this.currentlyPlayingAudio) {
                    this.currentlyPlayingAudio.isPlaying = !this.currentlyPlayingAudio.isPlaying;
                }
            },
            play() {
                this.currentlyPlayingAudio.isPlaying = true;
            },
            pause() {
                this.currentlyPlayingAudio.isPlaying = false;
            },
            //1 forward; -1 backward
            playNext(val) {
                let index = this.playlist.findIndex(a => a === this.currentlyPlayingAudio);
                index += val;
                index %= this.playlist.length;
                //Updating the current audio file
                this.setCurrentPlaylistIndex(index);
                //Start playing the song
                this.play();
            },
            onSongEnded() {
                //Current behavior is just to pause the player. In the future we could play the next song
                this.pause();
            }
        });

        app.component("AudioPlayer", AudioPlayer);
        app.component("PlayButton", PlayButton);
        app.provide("$sfPlayer", app.config.globalProperties.$sfPlayer);
    }
};
