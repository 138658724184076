import dayjs from "dayjs";

import {App, Plugin} from "vue";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import sfPriceInDollars from "@songfinch/shared/helpers/sfPriceInDollars";
dayjs.extend(utc);
dayjs.extend(timezone);
declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $dayjs: dayjs;
        $defaultImage: "static/image_placeholder.png";
        $sfPriceInDollars: typeof sfPriceInDollars
    }
}

const sfHelper: Plugin = {
    install(app: App) {
        app.config.globalProperties.$dayjs = dayjs;
        app.config.globalProperties.$defaultImage = "static/image_placeholder.png";
        app.config.globalProperties.$sfPriceInDollars = sfPriceInDollars;

        app.provide("sfPriceInDollars", app.config.globalProperties.$sfPriceInDollars);
    },
};

export default sfHelper;
