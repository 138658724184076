export default function (
    price: number | string,
    options: Intl.NumberFormatOptions = {}
): "Invalid format" | string {
    try {
        return new Intl.NumberFormat("en-EN", {
            style: "currency",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency: "USD",
            ...options,
        }).format(price / 100);
    } catch (e) {
        return "Invalid format";
    }
}
