<template>
    <div class="imgRoundBox" :class="{notRound, overflowHidden}" :style="{backgroundImage: `url(${imageSrc})`}">
        <slot/>
        <img class="@hidden" :src="imageSrc" :alt="alt" @error="img = $defaultImage">
    </div>
</template>

<script>
    export default {
        name: "RoundImageWithDefault",
        props: {
            notRound: Boolean,
            overflowHidden: Boolean,
            alt: {
                type: String,
                default: ""
            },
            src: {
                type: String,
                default: ""
            },
            image: {
                type: [Object, String],
                default: "",
                required: true
            },
            imageFormat: {
                type: Object,
                default: () => ({
                    transformation: [{width: 720, crop: "scale"}]
                }),
            }
        },
        data() {
            return {
                img: this.image
            };
        },
        computed: {
            imageSrc() {
                return this.src || this.$cld.url(this.img || this.$defaultImage, this.imageFormat);
            }
        },
        watch: {
            image(val) {
                this.img = val;
            }
        }
    };
</script>

<style>
    @import "@songfinch/customer/stylesheets/includes/components/shared/_round-image-with-default.pcss";
</style>
