import {ref, Component} from "vue";
import {SweetAlertOptions} from "sweetalert2";

export type ModalSlideOptions = "top" | "bottom" | "center-start" | "center-end";

export type SfModalParams = {
    component: Component | (() => Promise<typeof import("*.vue")>); // Required, can be a component or an async import function
    props?: Record<string, unknown>; // Optional, allows arbitrary props
    events?: {
        [key: string]: (...args: unknown[]) => void; // Optional, additional event handlers
    };
    swalSettings?: SweetAlertOptions;
    slide?: ModalSlideOptions; // Optional, specifies slide direction
    responsive?: {
        [breakpoint: number]: ModalSlideOptions & SweetAlertOptions;
    };
    appClass?: string; // for useAdditionalAppClass
};

// 	component: Login, //required, Component or function with import for async load``
// 	props: {popup: 1, ....}, //optional
// 	events: {confirm: (data)=>{...do} }, //optional
// 	swalSettings: {showConfirmButton: true} //optional
//  slide: "direction", //optional, values: "top", "bottom, "center-start", "center-end" //optional
//  responsive: {1199: {slide: "bottom"}}
//  appClass: "" // for useAdditionalAppClass

export default ref<SfModalParams>();
